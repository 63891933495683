import { ModalKey, useOpenModal } from '@finn/ua-modals';
import { CTA, Image } from '@finn/ui-components';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ui-modules';
import { useIntersectionObserver } from '@finn/ui-utils';
import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useRef } from 'react';

import { PromoCosmicMetadata } from '~/types/UIModules';
import { parseToHtml } from '~/utils/html';

const useStyles = makeStyles((theme) => ({
  description: {
    padding: theme.spacing(4, 0),

    '& h4,& .h4': {
      ...theme.typography.h4,
      margin: theme.spacing(0),
    },
    '& h5,& .h5': {
      ...theme.typography.h5,
      margin: theme.spacing(0),
    },
    '& h6,& .h6': {
      ...theme.typography.h6,
      margin: theme.spacing(0),
    },
  },
  footerIcon: {
    marginTop: 'auto',
  },
  divider: {
    backgroundColor: 'transparent',
    height: theme.spacing(8),
  },
}));

type Props = {
  data: PromoCosmicMetadata;
  slug?: string;
};

const PromoContent: React.FunctionComponent<Props> = ({ data, slug = '' }) => {
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isInView = useIntersectionObserver(containerRef, 0.75, '0px', true);
  const isNEON = slug === 'promo-neon' || slug === 'neon-promo';
  const isJobAuto = slug === 'promo-b2b-job-auto-3-version-2';
  const openModal = useOpenModal();

  useEffect(() => {
    if (isNEON && isInView) {
      interactionTrackingEvent(TrackingEventName.NEON_CAMPAIGN_VIEWED, {});
    }
  }, [isInView]);

  const handleClick = useCallback(
    (CTAMetadata) => {
      if (isNEON) {
        interactionTrackingEvent(TrackingEventName.NEON_CAMPAIGN_CLICKED, {
          link: CTAMetadata.href,
        });
      }
      if (isJobAuto) {
        openModal(ModalKey.JOBAUTO_WHITEPAPER_MODAL);
      }
    },
    [isNEON]
  );

  return (
    <div ref={containerRef}>
      <Typography variant="h2">{data.title}</Typography>
      <Typography className={classes.description} component="div">
        {parseToHtml(data.description)}
      </Typography>
      {data.cta && (
        <CTA
          data={data.cta.metadata}
          textColor={data?.dark_text ? 'black' : 'white'}
          parentBG={data?.dark_text ? 'light' : 'dark'}
          onCTAClick={() => handleClick(data.cta.metadata)}
        />
      )}
      {data?.footer_icon?.url && (
        <>
          <Divider className={classes.divider} />
          <Box className={classes.footerIcon}>
            <Image src={data.footer_icon.url} />
          </Box>
        </>
      )}
    </div>
  );
};

export default PromoContent;
