import { LG_CONTAINER_WIDTH } from '@finn/atoms';
import { breakpointValues } from '@finn/atoms/Theme';
import { makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';
import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

import { PromoCosmicMetadata } from '~/types/UIModules';

import PromoContent from './PromoContent';
import PromoImage from './PromoImage';

type StyleProps = {
  isReversed: boolean;
  bgColor: string;
  darkText?: boolean;
};

const EXTRA_WIDTH = 180;
const BASE_MIN_HEIGHT_DESKTOP = 832;
const BASE_MIN_HEIGHT_MOBILE = 448;
const MIN_HEIGHT_MD = 760;
const MAX_HEIGHT_MD = 860;
const CONTENT_MIN_HEIGHT = 400;
const TRANSITION_ANIMATION = '0.8s cubic-bezier(0,.45,.45,1)';
const ANIMATION_TRANSFORM_START = 'translate(0, 50px)';
const ANIMATION_TRANSFORM_END = 'none';

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  maxWidthWrapper: {
    width: `calc(${LG_CONTAINER_WIDTH}px + ${EXTRA_WIDTH}px)`,
    maxWidth: breakpointValues.max,
    margin: `${theme.spacing(0)} auto`,
    overflow: 'hidden',
  },
  wrapper: {
    width: '100%',
    maxWidth: '100%',
    minHeight: BASE_MIN_HEIGHT_DESKTOP,
    [theme.breakpoints.up('sm')]: {
      minHeight: BASE_MIN_HEIGHT_MOBILE,
      display: 'flex',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: MIN_HEIGHT_MD,
      maxHeight: MAX_HEIGHT_MD,
    },
  },
  innerWrapper: {
    width: `100%`,
    justifyContent: 'flex-end',
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    backgroundColor: (props) => props.bgColor,
  },
  imageWrapper: {
    height: '100%',
    width: '50%',
    objectFit: 'cover',
    overflow: 'hidden',
    '& picture': {
      height: '100%',
    },
    order: 2,
  },
  imageWrapperReversed: {
    width: '50%',
    order: 1,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: CONTENT_MIN_HEIGHT,
    width: '50%',
    padding: theme.spacing(11.25),
    transition: TRANSITION_ANIMATION,
    opacity: 0,
    transform: ANIMATION_TRANSFORM_START,
    '&.visible': {
      opacity: 1,
      transform: ANIMATION_TRANSFORM_END,
    },
  },
  contentWrapperReversed: {
    order: 2,
    paddingLeft: theme.spacing(11.25),
  },
}));

type Props = {
  data: PromoCosmicMetadata;
  slug?: string;
};

const PromoDesktop: React.FunctionComponent<Props> = ({ data, slug = '' }) => {
  const classes = useStyles({
    isReversed: data.reverse,
    bgColor: data.color_hex,
    darkText: data.dark_text,
  });

  const containerRef = useRef();
  const { enterCount } = useInViewport(
    containerRef,
    {
      threshold: 0.4,
    },
    { disconnectOnLeave: true },
    {}
  );

  const visible = enterCount >= 1;

  return (
    <div className={classes.maxWidthWrapper} ref={containerRef}>
      <div className={classes.innerWrapper}>
        <div className={classes.wrapper}>
          <div
            className={cn(classes.contentWrapper, {
              'text-black': data.dark_text,
              'text-white': !data.dark_text,
              [classes.contentWrapperReversed]: data.reverse,
              visible,
            })}
          >
            <PromoContent data={data} slug={slug} />
          </div>

          <div
            className={cn(classes.imageWrapper, 'bg-black', {
              [classes.imageWrapperReversed]: data.reverse,
            })}
          >
            <PromoImage
              src={data.image?.url}
              altText={data?.image_alt_text || data.title}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoDesktop;
